@import "@/scss/responsive.scss";

.collections {
  display: flex;
  gap: 0.5rem;
  // center grid items if it doesnt have 4
  justify-items: center;

  @include mobile {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
