@import "@/scss/responsive.scss";

.collection {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  align-content: start;
  flex-wrap: wrap;
  margin: 0 auto;
  position: relative;
  // background: var(--primary);
  // border-radius: 1rem;
  overflow: hidden;
  text-decoration: none;
  transition: opacity 0.3s ease-in-out;
  width: 100%;

  &:hover,
  &:focus {
    opacity: 0.9;
  }

  img {
    width: 100%;
    height: 550px;
    object-fit: cover;
  }

  video {
    width: 100%;
    height: 550px;
    object-fit: cover;
  }
}

.title {
  color: var(--primary);
  padding: 2rem 3rem 2rem 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  gap: 1rem;

  h1 {
    text-align: center;
    line-height: 1.5;
    font-size: 0.9em;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.05rem;
  }
}

.avatar {
  width: 60px;
  height: 60px;
  // position: absolute;
  // top: 0;
  // left: 50%;
  // transform: translate(-50%, -50%);
  z-index: 999;
  border-radius: 5rem;
  overflow: hidden;
  margin-top: -4rem;

  img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    object-position: center;
  }
}
