@import "@/scss/responsive";

.destaqueGrid {
  gap: 0.5rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  @include mobile {
    grid-template-columns: 1fr;
  }
}
