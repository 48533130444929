.title {
  width: 100%;
  padding: 2rem;
  gap: 1rem;
  display: grid;
  grid-template-columns: 1fr;
  max-width: 500px;
  margin: 0 auto;

  h1 {
    font-size: 1.5rem;
    text-align: center;
    color: var(--primary);
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.05em;
    font-family: var(--font-mirador);
  }

  p {
    font-size: 0.8rem;
    text-align: center;
    color: var(--text-secondary);
    text-transform: uppercase;
    line-height: 1.8;
    font-weight: 500;
  }
}

.title_smaller {
  width: 100%;
  padding: 1.5rem 0;
  gap: 0.75rem;
  display: grid;
  grid-template-columns: 1fr;
  max-width: 500px;
  margin: 0 auto;

  h1 {
    font-size: 1.2rem;
    text-align: center;
    color: var(--primary);
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.05em;
    font-family: var(--font-mirador);
  }

  p,
  a {
    font-size: 0.7rem;
    text-align: center;
    color: var(--text-secondary);
    text-transform: uppercase;
    line-height: 1.8;
    font-weight: 500;
  }
}
