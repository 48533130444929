@import "@/scss/responsive.scss";

.destaque {
  gap: 30px;
  min-height: 300px;
  display: flex;
  padding: 0 50px;
  // max-height: 600px;
  // border-radius: 20px;
  flex-direction: column;
  background-size: cover;
  background-repeat: none;
  background-position: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  max-width: 1600px;
  position: relative;
  // height: 400px;

  @include mobile {
    padding: 0 20px;
  }

  &__title {
    font-weight: 300;
    font-size: 3rem;
    // letter-spacing: 5px;

    text-transform: uppercase;
    color: var(--text-negative);
    text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.8);
    letter-spacing: 0.15rem;
    text-align: center;
    z-index: 1;
    font-family: var(--font-mirador);
    font-weight: 600;

    @include mobile {
      font-size: 2rem;
      word-break: break-word;
    }
  }

  &__text {
    font-size: 1.1rem;
    text-align: center;
    //text-transform: uppercase;
    text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5);
    color: var(--text-negative);
    z-index: 1;
  }

  &__button {
    gap: 10px;
    font-weight: 500;
    font-size: 0.9rem;
    padding: 12px 20px;
    // border-radius: 30px;
    letter-spacing: 1px;
    align-items: center;
    display: inline-flex;
    text-decoration: none;
    text-transform: uppercase;
    color: var(--text-negative);
    border: 1px solid var(--text-negative);
    transition: background-color 0.3s ease-in-out;
    z-index: 1;

    &__text {
      background-color: transparent;
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }

    svg {
      width: 22px;
      height: 22px;
    }
  }

  &__video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
    pointer-events: none;
  }
}

.destaqueGrid {
  gap: 30px;
  display: grid;
  grid-template-columns: auto;
  border: 1px solid red;
}
