.dots {
  position: absolute;
  bottom: 1rem;
  left: 0;
  right: 0;
  text-align: center;
  display: flex;
  gap: 0.75rem;
  align-items: center;
  justify-content: center;
  max-width: 1600px;
  div {
    width: 6px;
    height: 6px;
    background: rgba(255, 255, 255, 0.5);
    border: 0;
    border-radius: 2px;
    appearance: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &.active {
      background: #fff;
      width: 15px;
    }
  }
}
